import React from 'react';
import { getEditorColor } from '../../util/ColorUtil';
import { modifyLinks, renderTextColor } from '../../util/DataUtil';

const SmallLogoWall = ({
  data,
  text,
  renderLogos,
  returnBackground,
  getWidth,
  getAlignment,
}) => {
  const emphasizedTextColor = getEditorColor(text);

  return (
    <div className={'small-logo-wall'}>
      <div className={'inner-container'}>
        {text && (
          <div className={'text ' + getAlignment()}>
            <p
              className={`${renderTextColor(data?.regionColor)} ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              dangerouslySetInnerHTML={{ __html: modifyLinks(text) }}
            />
          </div>
        )}
        {!getWidth() && (
          <div className={'logos-container ' + returnBackground()}>
            {renderLogos()}
          </div>
        )}
      </div>
      {getWidth() && (
        <div className={'logos-container ' + returnBackground()}>
          {renderLogos()}
        </div>
      )}
    </div>
  );
};

export default SmallLogoWall;
