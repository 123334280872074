import React from 'react';
import { getEditorColor } from '../../util/ColorUtil';
import { modifyLinks, renderTextColor } from '../../util/DataUtil';

const LargeLogoWall = ({ data, text, renderLogos, getAlignment }) => {
  const emphasizedTextColor = getEditorColor(text);

  return (
    <div className={'large-logo-wall'}>
      <div className={'inner-container'}>
        {text && (
          <div className={'text ' + getAlignment()}>
            <p
              className={`${renderTextColor(data?.regionColor)} ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              dangerouslySetInnerHTML={{ __html: modifyLinks(text) }}
            />
          </div>
        )}
      </div>
      <div className={'logos-container'}>{renderLogos()}</div>
    </div>
  );
};

export default LargeLogoWall;
